<template>
    <div class="available-content">
        <div class="active-gamer" :class="{ available: expansionAccess.base }">
            <img src="../../assets/icons/guild-wars-2-color.png" alt="Guild Wars 2" />
            <div class="active-gamer-info">
                <div class="active-gamer-name">Guild Wars 2</div>
                <div class="active-game">
                    Dungeons & Fractals:
                    <span class="expansion-status" :class="{ enabled: expansionAccess.base }">
                        {{ expansionAccess.base ? 'Da' : 'Ne' }}
                    </span>
                </div>
            </div>
        </div>

        <div class="active-gamer" :class="{ available: expansionAccess.hot }">
            <img src="../../assets/icons/heart-of-thorns-color.png" alt="Guild Wars 2: Heart of Thorns" />
            <div class="active-gamer-info">
                <div class="active-gamer-name">Heart of Thorns</div>
                <div class="active-game">
                    Raid wings 1-4:
                    <span class="expansion-status" :class="{ enabled: expansionAccess.hot }">
                        {{ expansionAccess.hot ? 'Da' : 'Ne' }}
                    </span>
                </div>
            </div>
        </div>

        <div class="active-gamer" :class="{ available: expansionAccess.pof }">
            <img src="../../assets/icons/path-of-fire-color.png" alt="Guild Wars 2: Path of Fire" />
            <div class="active-gamer-info">
                <div class="active-gamer-name">Path of Fire</div>
                <div class="active-game">
                    Raid wings 5-7:
                    <span class="expansion-status" :class="{ enabled: expansionAccess.pof }">
                        {{ expansionAccess.pof ? 'Da' : 'Ne' }}
                    </span>
                </div>
            </div>
        </div>

        <div class="active-gamer" :class="{ available: expansionAccess.eod }">
            <img src="../../assets/icons/end-of-dragons-color.png" alt="Guild Wars 2: End of Dragons" />
            <div class="active-gamer-info">
                <div class="active-gamer-name">End of Dragons</div>
                <div class="active-game">
                    Strike missions*:
                    <span class="expansion-status" :class="{ enabled: expansionAccess.eod }">
                        {{ expansionAccess.eod ? 'Da' : 'Ne' }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccountInfo',

    props: {
        expansionAccess: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
