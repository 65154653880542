<template>
    <div class="event-signup container fade-in">
        <div class="content">
            <h2>CM event signup</h2>

            <p class="api-key-instructions-toggler">Unesi svoj GW2 API key</p>

            <p class="instructions">
                Ako nemate API key, ulogirajte se u ArenaNet account i kliknite na
                <a href="https://account.arena.net/applications" target="_blank">applications</a> tab. Kreirajte API key
                s uključenim <strong>account</strong>, <strong>characters</strong> i <strong>guild</strong> pravima te
                ga kopirajte u signup formu.
            </p>

            <form @submit.prevent="fetchAccountAndCharacters" class="input-group">
                <input v-model="apiKey" type="text" placeholder="GW2 API Key" />
                <button type="submit" :disabled="!gw2ApiFetchEnabled">Fetch</button>
            </form>

            <div v-if="accountInfo && characters" class="character-signup-info fade-in">
                <h2>{{ accountInfo.name }}</h2>
                <p class="instructions"><strong>Član guilda:</strong> {{ isMemberOfCroatianManiacs ? 'Da' : 'Ne' }}</p>

                <h2>Account info</h2>

                <AccountInfo :expansionAccess="expansionAccess" />

                <p class="instructions">
                    *Za pristup strike misijama potrebna je Icebrood Saga i/ili End of Dragons ekspanzija. Vidi
                    <router-link :to="{ name: 'Strike missions' }">Strike missions</router-link>
                    za popis misija.
                </p>

                <div v-if="fetchingEventsDone" class="event-list">
                    <h2>Događanja</h2>

                    <template v-if="events?.length">
                        <p class="instructions">1. Odaberi event</p>
                        <EventItem
                            v-for="event in upcomingEvents"
                            :key="event.id"
                            @setSelectedEvent="setSelectedEvent"
                            @cancelSignup="cancelSignup"
                            :event="event"
                            :userInfo="accountInfo"
                            :selectedEvent="activeEvent"
                        />
                    </template>

                    <p v-else>Nema aktivnih događanja</p>
                </div>

                <template v-if="events && events.length">
                    <h2>Characters</h2>

                    <p class="instructions">2. Odaberi lika s kojim želiš prisustvovati</p>

                    <div class="characters">
                        <CharacterCard
                            v-for="character in characters"
                            :key="character"
                            @setActiveCharacter="setActiveCharacter"
                            :character="character"
                            :apiKey="apiKey"
                            :activeCharacter="activeCharacter"
                        />
                    </div>

                    <button
                        @click="submitSignup"
                        class="sign-me-up"
                        :disabled="!activeCharacter || !activeEvent || submitDisabled"
                    >
                        <div v-if="submitDisabled" class="lds-dual-ring"></div>
                        <template v-else>Prijavi se</template>
                    </button>
                </template>
            </div>

            <div v-if="fetchingData" class="loader"></div>

            <div v-if="errorVisible" class="error">Account info nedostupan. Provjeri API key.</div>
        </div>

        <div v-if="snackbarMessage" class="snackbar fade-in-fast" :class="snackbarStyling">
            {{ snackbarMessage }}
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import AccountInfo from '@/components/eventSignup/AccountInfo.vue'
import CharacterCard from '@/components/eventSignup/CharacterCard.vue'
import EventItem from '@/components/eventSignup/EventItem.vue'

import fetchEvents from '@/mixins/fetchEvents'

import metadata from '@/utils/metadata.js'

const API_BASE_ENDPOINT = 'https://api.guildwars2.com/v2'
const API_CHARACTER_ENDPOINT = `${API_BASE_ENDPOINT}/characters`
const API_ACCOUNT_ENDPOINT = `${API_BASE_ENDPOINT}/account`
const LOCAL_STORAGE_KEY = '_cmESkey'

export default {
    name: 'EventSignup',

    metaInfo: {
        meta: metadata.eventSignup,
        title: 'Event signup | Croatian Maniacs - Guild Wars 2 guild & Gaming Community',
        link: [{ rel: 'canonical', href: 'https://croatian-maniacs.com/event-signup' }],
    },

    props: {},

    data() {
        return {
            apiKey: '',
            // myApiKey: '5A7ACFA9-4D94-0840-BB2D-643383C8BE391C6D3843-83DA-4B51-B9B9-72DA7458DCB6',
            // dummyAccountOnlyKey: '5148D117-2BC8-5945-A85A-0F76D27334816F515E6F-8E28-44A7-88C7-6D1C3069CF34',
            // dummyNoCM: '0A2CCE1C-FE8A-D944-BAF7-D7FAD217ABE458935A63-1B8F-4B6A-91E4-8A66693412FB',
            // hotAccount: '1F451CF2-C00E-B64D-9B42-C805E1BE7D67278FBE65-E894-4CD9-A172-F3EB8CB09B1A',
            cmGuildId: 'F3440A28-5337-4218-8691-2706C56B9149',
            accountInfo: null,
            characters: null,
            fetchingData: false,
            errorVisible: false,
            activeCharacter: null,
            activeEvent: null,
            snackbarMessage: '',
            snackbarStyling: '',
            submitDisabled: false,
        }
    },

    mixins: [fetchEvents],

    created() {
        this.setApiKey()

        this.fetchEvents()
    },

    components: { EventItem, CharacterCard, AccountInfo },

    computed: {
        isMemberOfCroatianManiacs() {
            return this.accountInfo?.guilds.includes(this.cmGuildId)
        },

        expansionAccess() {
            return {
                base: this.accountInfo?.access.includes('GuildWars2'),
                hot: this.accountInfo?.access.includes('HeartOfThorns'),
                pof: this.accountInfo?.access.includes('PathOfFire'),
                eod: this.accountInfo?.access.includes('EndOfDragons'),
            }
        },

        gw2ApiFetchEnabled() {
            return this.apiKey.length > 20
        },
    },

    methods: {
        fetchAccountAndCharacters() {
            if (this.gw2ApiFetchEnabled) {
                const accountInfo = axios.get(`${API_ACCOUNT_ENDPOINT}?access_token=${this.apiKey}`)
                const charactersInfo = axios.get(`${API_CHARACTER_ENDPOINT}?access_token=${this.apiKey}`)

                this.resetState()

                this.saveApiKeyToLocalStorage()

                this.fetchingData = true

                Promise.all([accountInfo, charactersInfo])
                    .then(results => {
                        this.accountInfo = results[0].data

                        this.characters = results[1].data.sort()

                        this.fetchingData = false
                    })
                    .catch(errors => {
                        console.log(errors)

                        this.fetchingData = false

                        this.errorVisible = true
                    })
            }
        },

        setActiveCharacter(selectedCharacter) {
            this.activeCharacter = selectedCharacter
        },

        saveApiKeyToLocalStorage() {
            localStorage.setItem(LOCAL_STORAGE_KEY, this.apiKey)
        },

        resetState() {
            this.accountInfo = null

            this.characters = null

            this.errorVisible = false

            this.activeEvent = null

            this.activeCharacter = null
        },

        submitSignup() {
            if (!this.submitDisabled) {
                const url =
                    process.env.NODE_ENV === 'production'
                        ? '/signups/events/signup/'
                        : 'http://localhost/signups/events/signup/'

                const payload = {
                    accountName: this.accountInfo.name,
                    character: this.activeCharacter,
                    eventId: this.activeEvent,
                    apiKey: this.apiKey,
                }

                this.submitDisabled = true

                axios
                    .post(url, payload)
                    .then(() => {
                        this.activeCharacter = null

                        this.activeEvent = null

                        this.fetchEvents()

                        this.toggleSnackbar({
                            message: 'Hvala na prijavi',
                            style: 'success',
                        })

                        this.submitDisabled = false
                    })
                    .catch(error => {
                        this.toggleSnackbar({
                            message: error.response.data?.error || 'Sad quaggans everywhere... (╥﹏╥)',
                            style: 'fail',
                        })

                        this.submitDisabled = false
                    })
            }
        },

        cancelSignup(canceledSignup) {
            if (!this.submitDisabled) {
                const url =
                    process.env.NODE_ENV === 'production'
                        ? '/signups/events/signup/'
                        : 'http://localhost/signups/events/signup/'

                const payload = {
                    accountName: this.accountInfo.name,
                    character: canceledSignup.characterName,
                    eventId: canceledSignup.eventId,
                    apiKey: this.apiKey,
                }

                this.submitDisabled = true

                axios
                    .delete(url, { data: payload })
                    .then(() => {
                        this.toggleSnackbar({
                            message: 'Ode nam jedan (╥﹏╥)',
                            style: 'success',
                        })

                        this.submitDisabled = false

                        this.fetchEvents()
                    })
                    .catch(error => {
                        this.toggleSnackbar({
                            message: error.response.data?.error || 'Sad quaggans everywhere... (╥﹏╥)',
                            style: 'fail',
                        })

                        this.submitDisabled = false
                    })
            }
        },

        setApiKey() {
            const previousApiKey = localStorage.getItem(LOCAL_STORAGE_KEY)

            if (previousApiKey) {
                this.apiKey = previousApiKey

                this.fetchAccountAndCharacters()
            }
        },

        setSelectedEvent(selectedEvent) {
            this.activeEvent = selectedEvent.id
        },

        toggleSnackbar(settings) {
            this.snackbarMessage = settings.message

            this.snackbarStyling = settings.style

            setTimeout(() => {
                this.snackbarStyling = `${this.snackbarStyling} hidden`

                setTimeout(() => {
                    this.snackbarMessage = null

                    this.snackbarStyling = null
                }, 1000)
            }, 4000)
        },
    },
}
</script>
