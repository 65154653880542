<template>
    <div class="hero-header">
        <div class="header-bg home-header"></div>

        <div class="stripes">
            <div class="container">
                <h1 class="reveal" v-reveal="{ delay: 180 }">
                    Gaming community<br /><span class="text-highlight">rebooted</span>!
                </h1>
                <h2 class="reveal" v-reveal="{ delay: 280 }">
                    Dobrodošli u regionalnu skupinu zaljubljenika u gaming i druženje
                </h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeroHeader',

    props: {},

    data() {
        return {
            // animationStarted: false,
        }
    },

    created() {},

    mounted() {
        // setTimeout(() => {
        //     this.animationStarted = true
        // }, 500)
    },

    components: {},

    computed: {},

    methods: {},
}
</script>
