<template>
    <div class="streamer-spotlight" :class="streamer.bgStyle">
        <div class="container">
            <div class="content">
                <div class="streamer" :class="{ reversed: streamer.isReversed }">
                    <div class="streamer-profile" v-reveal>
                        <img class="profile-image" :src="profileImage" :alt="streamer.name" />
                    </div>

                    <div class="streamer-bio">
                        <h3 v-reveal>{{ streamer.name }}</h3>

                        <p v-reveal>{{ streamer.description }}</p>

                        <div class="streamer-links" v-reveal>
                            <div v-if="streamer.links.twitch" class="twitch-cta">
                                <a :href="streamer.links.twitch" class="cta-btn twitch" target="_blank">
                                    <img class="twitch" src="../../../assets/icons/ico-twitch.svg" alt="Twitch" />
                                    Follow on Twitch
                                </a>
                            </div>

                            <div class="social-media">
                                <a v-if="streamer.links.instagram" :href="streamer.links.instagram" target="_blank">
                                    <img
                                        class="instagram"
                                        src="../../../assets/icons/ico-instagram.svg"
                                        alt="Instagram"
                                    />
                                </a>

                                <a v-if="streamer.links.youtube" :href="streamer.links.youtube" target="_blank">
                                    <img class="youtube" src="../../../assets/icons/ico-youtube.svg" alt="Youtube" />
                                </a>

                                <a v-if="streamer.links.twitter" :href="streamer.links.twitter" target="_blank">
                                    <img class="twitter" src="../../../assets/icons/ico-twitter.svg" alt="Twitter" />
                                </a>

                                <a v-if="streamer.links.trovo" :href="streamer.links.trovo" target="_blank">
                                    <img src="../../../assets/icons/ico-trovo.svg" alt="Trovo" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StreamerSpotlight',

    props: {
        streamer: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {
        profileImage() {
            // eslint-disable-next-line
            return require(`../../../assets/streamers/${this.streamer.name}.jpg`)
        },
    },

    methods: {},
}
</script>
