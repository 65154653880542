<template>
    <div @click="emitSelectedCharacter" class="selective-tab" :class="{ selected }">
        <img :src="professionImage" :alt="characterInfo?.profession || 'unknown'" />
        <div class="tab-info">
            <div class="tab-title">{{ character }}</div>
            <div class="tab-description">{{ characterText }}</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

const API_BASE_ENDPOINT = 'https://api.guildwars2.com/v2'
const SUPPORTED_PROFESSIONS = [
    'Engineer',
    'Necromancer',
    'Thief',
    'Elementalist',
    'Warrior',
    'Ranger',
    'Mesmer',
    'Guardian',
    'Revenant',
]

const unknownProfession = require('../../assets/icons/unknown.png')

export default {
    name: 'CharacterCard',

    props: {
        character: {
            required: true,
            type: String,
        },

        apiKey: {
            required: true,
            type: String,
        },

        activeCharacter: {
            type: String,
        },
    },

    data() {
        return {
            characterInfo: null,
        }
    },

    created() {
        this.fetchCharacterInfo()
    },

    components: {},

    computed: {
        characterText() {
            return this.characterInfo
                ? `lv${this.characterInfo.level} ${this.characterInfo.gender} ${this.characterInfo.race} ${this.characterInfo.profession}`
                : ''
        },

        professionImage() {
            return this.characterInfo && SUPPORTED_PROFESSIONS.includes(this.characterInfo.profession)
                ? require(`../../assets/icons/${this.characterInfo.profession}.png`)
                : unknownProfession
        },

        selected() {
            return this.character === this.activeCharacter
        },
    },

    methods: {
        fetchCharacterInfo() {
            axios
                .get(`${API_BASE_ENDPOINT}/characters/${this.character}/core?access_token=${this.apiKey}`)
                .then(response => {
                    this.characterInfo = response.data
                })
                .catch(error => console.log(`Error fetching info for ${this.character}`, error))
        },

        emitSelectedCharacter() {
            this.$emit('setActiveCharacter', this.character)
        },
    },
}
</script>
