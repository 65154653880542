<template>
    <div class="nutty-gamer">
        <div class="container">
            <div class="content nutty-gamer-content-wrap">
                <div class="nutty-gamer-info">
                    <h2 v-reveal>Nutty Gamer</h2>

                    <p v-reveal>
                        Nutty Gamer je community (koliko je to moguće) odraslih gamera koji dio svojeg slobodnog vremena
                        provodi u igrama.
                    </p>

                    <p v-reveal>
                        Iako je večina našh članova usko vezana za CM, ne igramo samo Guild Wars! U zadnjih 17 godina
                        druženja upoznali smo i skupa posjetili brojne fantasy i sci-fi svjetove kako u singleplayer
                        tako i u co-op / MMO naslovima.
                    </p>

                    <p v-reveal>
                        Osim toga, redovno organiziramo community nagradne igre i druženja uz neizbježnu kombinaciju
                        piva i čvarkuša!
                    </p>
                </div>

                <div class="nutty-gamer-discord-cta">
                    <a href="https://discord.croatian-maniacs.com/" class="cta-btn discord" v-reveal>
                        <img src="../../../assets/discord-logo.svg" alt="Discord" /> Join Discord
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NuttyGamerInfo',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
