<template>
    <div @click="emitSelectedEvent" class="selective-tab" :class="{ selected, 'signed-up': userAlreadySignedUp }">
        <img
            v-if="userAlreadySignedUp"
            @click="cancelSignup"
            src="../../assets/star.svg"
            alt="Signup star"
            class="sign-up-star"
        />
        <img :src="eventImage" :alt="event.name" />
        <div class="tab-info">
            <div class="tab-title">{{ event.name }}</div>
            <div class="event-time">{{ eventTime }}</div>
            <div class="tab-description">{{ event.description }}</div>
            <div v-if="event.signups.length" class="signups">Prijave ({{ event.signups.length }}): {{ signups }}</div>
        </div>
    </div>
</template>

<script>
import { format, setDefaultOptions } from 'date-fns'
import { hr } from 'date-fns/locale'

import EventTypes from '@/utils/EventTypes'

setDefaultOptions({ locale: hr })

const EVENT_TYPES = EventTypes

export default {
    name: 'EventItem',

    props: {
        event: {
            required: true,
            type: Object,
        },

        selectedEvent: {
            type: String,
        },

        userInfo: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {
        selected() {
            return this.event.id === this.selectedEvent
        },

        eventImage() {
            return EVENT_TYPES.includes(this.event.type)
                ? require(`../../assets/icons/${this.event.type}.png`)
                : require(`../../assets/icons/unknown.png`)
        },

        signups() {
            return this.event.signups.map(signup => signup.accountName.split('.')[0]).join(', ')
        },

        userAlreadySignedUp() {
            const filteredSignups = this.event.signups.filter(signup => signup.accountName === this.userInfo.name)

            return Boolean(filteredSignups.length)
        },

        eventTime() {
            return this.event.time ? `${format(new Date(this.event.time), 'EEEE dd.MM. HH:mm')}h` : '//'
        },
    },

    methods: {
        emitSelectedEvent() {
            if (!this.userAlreadySignedUp) {
                this.$emit('setSelectedEvent', this.event)
            }
        },

        cancelSignup() {
            const characterName = this.event.signups.find(signup => signup.accountName === this.userInfo.name).character
            this.$emit('cancelSignup', { eventId: this.event.id, characterName })
        },
    },
}
</script>
