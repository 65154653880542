<template>
    <div v-if="status" class="currently-active">
        <div class="container">
            <div class="content">
                <h2 v-reveal>{{ activeGamers.length ? 'Ingame' : 'Active' }} members</h2>

                <div class="active-gamers-list">
                    <div v-for="gamer in activeGamers" :key="gamer.id" class="active-gamer" v-reveal>
                        <img :src="gamer.avatar_url" :alt="gamer.username" />
                        <div class="active-gamer-info">
                            <div class="active-gamer-name">{{ gamer.username }}</div>

                            <div class="active-game">
                                <span class="playing">Playing:</span>
                                <span class="truncate">{{ gamer.game.name }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="active-gamer" v-reveal>
                        <img src="../../assets/nutty-gamer-discord-icon.jpg" alt="Nutty Gamer" />
                        <div class="active-gamer-info">
                            <div class="active-gamer-name">Discord status</div>

                            <div class="active-game">
                                <span class="playing">Online:</span>
                                <span class="truncate">{{ totalMembers.count }} {{ totalMembers.text }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="nutty-gamer-discord-cta">
                    <a href="https://discord.croatian-maniacs.com/" class="cta-btn discord" v-reveal>
                        <img src="../../assets/discord-logo.svg" alt="Discord" /> Join Discord
                    </a>

                    <a
                        href="https://worldofwarcraft.blizzard.com/en-us/invite/jnEY9ERUAMJ?region=EU&faction=Alliance"
                        class="cta-btn discord"
                        target="_blank"
                        v-reveal
                    >
                        <img src="../../assets/icons/wow.png" alt="World of Warcraft" /> WoW Community
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DiscordStatus',

    props: {},

    data() {
        return {
            status: null,

            fetchDiscordStatusInterval: null,
        }
    },

    created() {
        this.fetchDiscordStatus()

        this.fetchDiscordStatusInterval = setInterval(() => {
            this.fetchDiscordStatus()
        }, 30000)
    },

    beforeDestroy() {
        if (this.fetchDiscordStatusInterval) {
            clearInterval(this.fetchDiscordStatusInterval)
        }
    },

    components: {},

    computed: {
        activeGamers() {
            if (this.status) {
                return this.status.members.filter(member => member.game)
            }

            return []
        },

        totalMembers() {
            const totalMembers = this.status ? this.status.members.length : 0

            return {
                count: totalMembers,
                text: totalMembers === 1 ? 'member' : 'members',
            }
        },
    },

    methods: {
        fetchDiscordStatus() {
            fetch('https://discord.com/api/guilds/93348055599808512/widget.json')
                .then(response => response.json())
                .then(data => (this.status = data))
                .catch(error => {
                    console.error('Error:', error)
                })
        },
    },
}
</script>
