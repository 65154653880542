<template>
    <div v-if="!is404Page" class="footer" :class="{ indented: isIndented }">Croatian Maniacs - {{ currentYear }}.</div>
</template>

<script>
export default {
    name: 'FooterSection',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {
        currentYear() {
            return new Date().getFullYear()
        },

        isIndented() {
            return this.$route.name === 'Homepage'
        },

        is404Page() {
            return this.$route.name === 'Page not found'
        },
    },

    methods: {},
}
</script>
