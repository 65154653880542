<template>
    <div class="home fade-in">
        <HeroHeader />
        <CroatianManiacsInfo />
        <NuttyGamerInfo />
        <GamesWePlay />
        <StreamersSection />
        <DiscordStatus />
    </div>
</template>

<script>
import CroatianManiacsInfo from '@/components/homepage/croatianManiacs/CroatianManiacsInfo.vue'
import DiscordStatus from '@/components/homepage/DiscordStatus.vue'
import HeroHeader from '@/components/homepage/HeroHeader.vue'
import GamesWePlay from '@/components/homepage/nuttyGamer/GamesWePlay.vue'
import NuttyGamerInfo from '@/components/homepage/nuttyGamer/NuttyGamerInfo.vue'
import StreamersSection from '@/components/homepage/streamers/StreamersSection.vue'

import metadata from '@/utils/metadata'

export default {
    name: 'HomePage',
    metaInfo: {
        meta: metadata.home,
        title: 'Croatian Maniacs - Guild Wars 2 guild & Gaming Community',
        link: [{ rel: 'canonical', href: 'https://croatian-maniacs.com' }],
    },

    components: { DiscordStatus, StreamersSection, GamesWePlay, NuttyGamerInfo, CroatianManiacsInfo, HeroHeader },
}
</script>
