<template>
    <div class="page-not-found">
        <div class="hero-header">
            <div class="hero-header baloon-golem"></div>

            <div class="stripes">
                <div class="container">
                    <h1 class="reveal" v-reveal="{ delay: 180 }">
                        <span class="text-highlight">404</span><br />not found
                    </h1>
                    <h2 class="reveal" v-reveal="{ delay: 280 }">Foooo - Are you lost?</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import metadata from '@/utils/metadata'

export default {
    name: 'PageNotFound',

    metaInfo: {
        meta: metadata.pageNotFound,
        title: 'Page not found | The fLuX c4Pac1tY mAtr1X is br0K3n...',
    },

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
