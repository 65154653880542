<template>
    <div class="activity-blocks">
        <div class="activity-row">
            <div class="guild-activity strikes effect-layla animate-left">
                <div class="overlay-light"></div>
                <div class="activity-content">
                    <h3 v-reveal>Strike nights</h3>
                    <div class="activity-description" v-reveal>Tjedni clear svih strike missiona</div>
                    <!--                    <a class="activity-btn" href="#">Strike guides</a>-->
                </div>
            </div>
            <div class="guild-activity raids effect-layla effect-layla-dark animate-right">
                <div class="activity-content dark">
                    <h3 v-reveal>Raid training</h3>
                    <div class="activity-description" v-reveal>Jer se legendary armor neće scraftati sam od sebe</div>
                </div>
                <!--                <a class="activity-btn" href="#">Raid guides</a>-->
            </div>
        </div>

        <div class="activity-row flipped-sm">
            <div class="guild-activity dungeoneering effect-layla effect-layla-dark dark animate-left">
                <div class="activity-content dark">
                    <h3 v-reveal>Daily dungeoneering</h3>
                    <div class="activity-description" v-reveal>
                        Token farm, gear upgrade, čisti gold profit i minglanje...
                    </div>
                </div>
            </div>
            <div class="guild-activity gw-activities effect-layla animate-right">
                <div class="overlay-light"></div>
                <div class="activity-content">
                    <h3 v-reveal>All things Guild Wars</h3>
                    <div class="activity-description" v-reveal>
                        Open world eventi, meta trains, bounties, WvW, PvP...
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CroatianManiacsActivityBlocks',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
