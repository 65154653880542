<template>
    <div class="streamer-section">
        <div class="streamers-heading">
            <div class="container">
                <div class="content">
                    <h2>Streamers</h2>
                </div>
            </div>
        </div>
        <div class="streamers">
            <StreamerSpotlight v-for="streamer in streamers" :key="streamer.name" :streamer="streamer" />
        </div>
    </div>
</template>

<script>
import streamers from '@/components/homepage/streamers/streamers'
import StreamerSpotlight from '@/components/homepage/streamers/StreamerSpotlight.vue'

export default {
    name: 'StreamersSection',

    props: {},

    data() {
        return {
            streamers,
        }
    },

    created() {},

    components: {
        StreamerSpotlight,
    },

    computed: {},

    methods: {},
}
</script>
