<template>
    <div class="strike-guides fade-in">
        <StrikeGuidesHeader />

        <div class="container">
            <div class="content">
                <h2 v-reveal>Što su strike misije?</h2>

                <p v-reveal>
                    Jednako kao i raidovi, strike misije su instance dizajnirane za squad od deset igrača te služe kao
                    uvod u Guild Wars 2 PVE endgame - odnosno, shvatite ih kao mini-raidove. Pronaći ćete ih kao dio
                    Icebrood Sage i End of Dragons ekspanzije, a gotovo svaka se sastoji od jednog bossa kojeg ste
                    upoznali tijekom glavnog storya uz nekoliko dodatnih mehanika.
                </p>

                <p v-reveal>
                    Ako smatrate da niste spremni raidati ili jednostavno nemate dovoljno dobar gear, a svejedno želite
                    biti dio mingle večeri na Discordu svaki tjedan s ostatkom ekipe - strike misije su idealno mjesto
                    za vas.
                </p>
            </div>
        </div>

        <StrikeBoxes />

        <div class="container">
            <div class="content">
                <h2 v-reveal>Builds</h2>

                <p v-reveal>
                    Buildovi se ne razlikuje previše od onih koje bi koristili i za raidanje (<a
                        href="https://hardstuck.gg/gw2/builds/"
                        target="_blank"
                        >Hardstruck</a>,
                    <a href="https://snowcrows.com/builds?profession=any&category=recommended" target="_blank">Snowcrows</a>) kao ni način na koji pristupamo strike misijama. Dapače, strike misije su idealno mjesto za
                    izvježbati reflekse i upoznati se sa načinom na koji Guild Wars prezentira određene mehanike kako bi
                    s vremenom mogli krenuti prema malo težem contentu.
                </p>

                <p v-reveal>
                    Kako je generalni interes za strike misije veći, pokušajte biti online barem 10-15min ranije od
                    dogovorenog vremena za tjedni Strikes night i u idealnom slučaju imati alta (ili dva) koji mogu
                    pokriti i support role (alacrity, quickness, heal).
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import StrikeBoxes from '@/components/strikeMissions/StrikeBoxes.vue'
import StrikeGuidesHeader from '@/components/strikeMissions/StrikeGuidesHeader.vue'

import metadata from '@/utils/metadata'

export default {
    name: 'StrikeMissions',

    metaInfo: {
        meta: metadata.strikeMissions,
        title: 'Strike Missions | Croatian Maniacs - Guild Wars 2 guild & Gaming Community',
        link: [{ rel: 'canonical', href: 'https://croatian-maniacs.com/strike-missions' }],
    },

    props: {},

    data() {
        return {}
    },

    created() {},

    components: { StrikeBoxes, StrikeGuidesHeader },

    computed: {},

    methods: {},
}
</script>
