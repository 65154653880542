import Vue from 'vue'

import RaidSignups from '@/views/EventSignup.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import RaidTraining from '@/views/RaidTraining.vue'
import StrikeMissions from '@/views/StrikeMissions.vue'

import VueRouter from 'vue-router'

import HomePage from '../views/HomePage.vue'

const EventAdmin = () => import('@/views/EventAdmin.vue')

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Homepage',
        component: HomePage,
    },
    {
        path: '/raids',
        name: 'Raids',
        component: RaidTraining,
    },
    {
        path: '/strike-missions',
        name: 'Strike missions',
        component: StrikeMissions,
    },
    {
        path: '/event-signup',
        name: 'Event signup',
        component: RaidSignups,
    },
    {
        path: '/event-admin',
        name: 'Event admin',
        component: EventAdmin,
    },
    {
        path: '*',
        name: 'Page not found',
        component: PageNotFound,
    },
]

const router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { x: 0, y: 0 }
    },
    linkActiveClass: '',
    linkExactActiveClass: 'active',
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
