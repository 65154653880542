const revealElement = (e, toggleClass) => {
    e.target.classList.add(toggleClass)
}

const scrollReveal = ({ target, revealThreshold, toggleClass }) => {
    target.classList.add(toggleClass.default.scrollingDown)

    // const wasAbove = false

    const callback = entries => {
        const entry = entries[0]

        const shouldReveal = entry.intersectionRatio > revealThreshold

        if (entry.isIntersecting && shouldReveal) {
            revealElement(entry, toggleClass.revealed)
        }
    }

    const observer = new IntersectionObserver(callback, {
        threshold: [0, revealThreshold],
    })

    observer.observe(target)
}

export default scrollReveal
