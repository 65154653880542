<template>
    <div id="cm-ng">
        <MainMenu />
        <router-view />
        <FooterSection />
    </div>
</template>

<script>
import FooterSection from '@/components/layout/FooterSection.vue'
import MainMenu from '@/components/layout/MainMenu.vue'

export default {
    components: { FooterSection, MainMenu },
}
</script>
