<template>
    <div class="main-menu">
        <div class="container">
            <div class="logo"><span class="highlighted">Croatian</span>Maniacs</div>

            <div @click="handleMobileMenuToggle" class="menu-toggle" :class="{ active: mobileMenuVisible }">
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div class="menu-items fade-in-fast" :class="{ toggled: mobileMenuVisible }">
                <router-link :to="{ name: 'Homepage' }">Home</router-link>
                <router-link :to="{ name: 'Raids' }">Raids</router-link>
                <router-link :to="{ name: 'Strike missions' }">Strike missions</router-link>
                <router-link :to="{ name: 'Event signup' }">Events</router-link>
            </div>
        </div>
    </div>
</template>

<script>
const MENU_TOGGLE_CLASS = 'mobile-menu-opened'

export default {
    name: 'MainMenu',

    data() {
        return {
            mobileMenuVisible: false,
            bodyRef: null,
            windowWidth: 0,
        }
    },

    mounted() {
        this.bodyRef = document.getElementsByTagName('body')[0]

        window.addEventListener('resize', this.handleWindowWidth)

        this.handleWindowWidth()
    },

    components: {},

    computed: {
        // isMobile() {
        //     return this.window
        // },
    },

    methods: {
        handleWindowWidth() {
            this.windowWidth = window.innerWidth

            if (this.windowWidth >= 768) {
                this.mobileMenuVisible = false

                this.unlockBodyScroll()
            }
        },

        handleMobileMenuToggle() {
            this.mobileMenuVisible = !this.mobileMenuVisible

            if (this.mobileMenuVisible) {
                this.lockBodyScroll()
            } else {
                this.unlockBodyScroll()
            }
        },

        lockBodyScroll() {
            this.bodyRef.classList.add(MENU_TOGGLE_CLASS)
        },

        unlockBodyScroll() {
            this.bodyRef.classList.remove(MENU_TOGGLE_CLASS)
        },
    },

    watch: {
        $route() {
            this.mobileMenuVisible = false

            this.bodyRef.classList.remove(MENU_TOGGLE_CLASS)
        },
    },
}
</script>
