import axios from 'axios'
import orderBy from 'lodash/orderBy'

export default {
    data: () => ({
        events: null,
        fetchingEventsDone: false,
    }),

    computed: {
        upcomingEvents() {
            const now = Date.now()

            return this.events.filter(event => event.time > now)
        },
    },

    methods: {
        fetchEvents() {
            const url = process.env.NODE_ENV === 'production' ? '/signups/events' : 'http://localhost/signups/events/'

            axios
                .get(url)
                .then(response => {
                    this.events = orderBy(response.data, ['time'], ['asc'])

                    this.fetchingEventsDone = true
                })
                .catch(error => {
                    console.log(error)

                    this.fetchingEventsDone = true
                })
        },
    },
}
