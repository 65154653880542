<template>
    <div class="hero-header">
        <div class="hero-header raiding-header-bg"></div>

        <div class="stripes">
            <div class="container">
                <h1 class="reveal" v-reveal="{ delay: 180 }">Raid <span class="text-highlight">training</span></h1>
                <h2 class="reveal" v-reveal="{ delay: 280 }">Jer se legendary armor neće scraftati sam od sebe</h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RaidingGuidesHeader',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
