<template>
    <div class="games-we-play">
        <div class="container">
            <div class="content">
                <h2>Što igramo</h2>

                <div class="games-list">
                    <div class="game effect-layla" v-reveal>
                        <img class="game-poster" src="../../../assets/gamesWePlay/gw2-box.jpg" alt="Guild Wars 2" />

                        <div class="info">
                            <a href="https://www.guildwars2.com/en/" target="_blank">
                                <img
                                    class="game-logo"
                                    src="../../../assets/gamesWePlay/gw2-logo.png"
                                    alt="Guild Wars 2"
                                />
                            </a>
                            <h3>Guild Wars 2</h3>
                            <div class="description">
                                <span class="tag">MMORPG</span>
                                <span class="tag">Fantasy</span>
                            </div>
                        </div>
                    </div>

                    <div class="game effect-layla" v-reveal>
                        <img
                            class="game-poster"
                            src="../../../assets/gamesWePlay/eso-box.jpg"
                            alt="Elder Scrolls Online"
                        />

                        <div class="info">
                            <a href="https://www.elderscrollsonline.com/" target="_blank">
                                <img
                                    class="game-logo"
                                    src="../../../assets/gamesWePlay/elder-scrolls-online-logo.png"
                                    alt="Elder Scrolls Online"
                                />
                            </a>
                            <h3>Elder Scrolls Online</h3>
                            <div class="description">
                                <span class="tag">MMORPG</span>
                                <span class="tag">Fantasy</span>
                            </div>
                        </div>
                    </div>

                    <div class="game effect-layla" v-reveal>
                        <img
                            class="game-poster"
                            src="../../../assets/gamesWePlay/wow-box.jpg"
                            alt="World of Warcraft"
                        />

                        <div class="info">
                            <a href="https://worldofwarcraft.com/" target="_blank">
                                <img
                                    class="game-logo"
                                    src="../../../assets/gamesWePlay/wow-logo.png"
                                    alt="World of Warcraft"
                                />
                            </a>
                            <h3>World of Warcraft</h3>
                            <div class="description">
                                <span class="tag">MMORPG</span>
                                <span class="tag">Fantasy</span>
                            </div>
                        </div>
                    </div>

                    <div class="game effect-layla" v-reveal>
                        <img class="game-poster" src="../../../assets/gamesWePlay/diablo4.jpg" alt="Diablo 4" />

                        <div class="info">
                            <a href="https://diablo4.blizzard.com/en-us/" target="_blank">
                                <img
                                    class="game-logo"
                                    src="../../../assets/gamesWePlay/diablo4-logo.png"
                                    alt="Diablo 4"
                                />
                            </a>

                            <h3>Diablo 4</h3>
                            <div class="description">
                                <span class="tag">ARPG</span>
                                <span class="tag">Co-op</span>
                            </div>
                        </div>
                    </div>

                    <div class="game effect-layla" v-reveal>
                        <img
                            class="game-poster"
                            src="../../../assets/gamesWePlay/eve-online-box.jpg"
                            alt="EVE Online"
                        />
                        <div class="info">
                            <a href="https://www.eveonline.com/" target="_blank">
                                <img
                                    class="game-logo"
                                    src="../../../assets/gamesWePlay/eve-online-logo.png"
                                    alt="EVE Online"
                                />
                            </a>
                            <h3>EVE Online</h3>
                            <div class="description">
                                <span class="tag">MMO</span>
                                <span class="tag">Sci-Fi</span>
                            </div>
                        </div>
                    </div>

                    <div class="game effect-layla" v-reveal>
                        <img
                            class="game-poster"
                            src="../../../assets/gamesWePlay/final-fantasy-online-box.jpg"
                            alt="Final Fantasy XIV"
                        />

                        <div class="info">
                            <a href="https://eu.finalfantasyxiv.com/" target="_blank">
                                <img
                                    class="game-logo"
                                    src="../../../assets/gamesWePlay/final-fantasy-online-logo.png"
                                    alt="Final Fantasy Online"
                                />
                            </a>

                            <h3>Final Fantasy XIV</h3>
                            <div class="description">
                                <span class="tag">MMORPG</span>
                                <span class="tag">Fantasy</span>
                            </div>
                        </div>
                    </div>

                    <div class="game effect-layla" v-reveal>
                        <img class="game-poster" src="../../../assets/gamesWePlay/dota-box.jpg" alt="DOTA 2" />

                        <div class="info">
                            <a href="https://www.dota2.com/" target="_blank">
                                <img class="game-logo" src="../../../assets/gamesWePlay/dota-2-logo.png" alt="DOTA 2" />
                            </a>

                            <h3>DOTA 2</h3>
                            <div class="description">
                                <span class="tag">MOBA</span>
                                <span class="tag">PvP</span>
                            </div>
                        </div>
                    </div>

                    <div class="game effect-layla" v-reveal>
                        <img
                            class="game-poster"
                            src="../../../assets/gamesWePlay/league-of-legends.jpg"
                            alt="League of Legends"
                        />

                        <div class="info">
                            <a href="https://www.leagueoflegends.com/" target="_blank">
                                <img
                                    class="game-logo"
                                    src="../../../assets/gamesWePlay/lol-logo.png"
                                    alt="League of Legends"
                                />
                            </a>

                            <h3>League of Legends</h3>
                            <div class="description">
                                <span class="tag">MOBA</span>
                                <span class="tag">PvP</span>
                            </div>
                        </div>
                    </div>

                    <div class="game effect-layla" v-reveal>
                        <img class="game-poster" src="../../../assets/gamesWePlay/smite-box.jpg" alt="SMITE" />

                        <div class="info">
                            <a href="https://www.smitegame.com/" target="_blank">
                                <img class="game-logo" src="../../../assets/gamesWePlay/smite-logo.png" alt="SMITE" />
                            </a>

                            <h3>SMITE</h3>
                            <div class="description">
                                <span class="tag">MOBA</span>
                                <span class="tag">PvP</span>
                            </div>
                        </div>
                    </div>

                    <div class="game effect-layla" v-reveal>
                        <img class="game-poster" src="../../../assets/gamesWePlay/poe-box.jpg" alt="Path of Exile" />

                        <div class="info">
                            <a href="https://www.pathofexile.com/" target="_blank">
                                <img
                                    class="game-logo"
                                    src="../../../assets/gamesWePlay/path-of-exile-logo.png"
                                    alt="Path of Exile"
                                />
                            </a>

                            <h3>Path of Exile</h3>
                            <div class="description">
                                <span class="tag">ARPG</span>
                                <span class="tag">Co-op</span>
                            </div>
                        </div>
                    </div>

                    <div class="game effect-layla" v-reveal>
                        <img
                            class="game-poster"
                            src="../../../assets/gamesWePlay/genshin-impact-box.jpg"
                            alt="Genshin Impact"
                        />

                        <div class="info">
                            <a href="https://genshin.hoyoverse.com/en/" target="_blank">
                                <img
                                    class="game-logo"
                                    src="../../../assets/gamesWePlay/genshin-impact-logo.png"
                                    alt="Genshin Impact"
                                />
                            </a>

                            <h3>Genshin Impact</h3>
                            <div class="description">
                                <span class="tag">Co-op</span>
                                <span class="tag">Anime</span>
                            </div>
                        </div>
                    </div>

                    <div class="game effect-layla" v-reveal>
                        <img
                            class="game-poster"
                            src="../../../assets/gamesWePlay/forza-horizon-box.jpg"
                            alt="Forza Horizon"
                        />

                        <div class="info">
                            <a href="https://forza.net/" target="_blank">
                                <img
                                    class="game-logo"
                                    src="../../../assets/gamesWePlay/forza-horizon-logo.png"
                                    alt="Forza Horizon"
                                />
                            </a>

                            <h3>Forza Horizon</h3>
                            <div class="description">
                                <span class="tag">Racing</span>
                                <span class="tag">Co-op</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GamesWePlay',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
