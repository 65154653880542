<template>
    <div class="raid-guides fade-in">
        <RaidingGuidesHeader />

        <div class="container">
            <div class="content">
                <h2 v-reveal>Što su raidovi?</h2>

                <p v-reveal>
                    Raidovi su dizajnirani kao instance za deseteročlane squadove te se smatraju najtežim contentom u
                    Guild Warsu 2. Osim zasebnih mehanika koje članovi squada moraju savladati, zahtijevaju gotovo
                    uvijek i specijalizirane role za svaki boss encounter.
                </p>

                <p v-reveal>
                    Ako ste odlučili započeti raditi na legendary armoru ili jednostavno poboljšati sebe kao igrača,
                    pridružite nam se u raid training večerima gdje ćemo proći inicijalne raid wingove i naučiti vas
                    kako savladati mehanike i postati bolji u klasi koju igrate.
                </p>
            </div>
        </div>

        <div class="raiding-mindset">
            <div class="container">
                <div class="content">
                    <h2 v-reveal>Raiding mindset</h2>

                    <p v-reveal>
                        Najčešći raiding setup se sastoji od 2 sub-grupe od 5 igrača sa bar 3 DPSa i 2 support igrača u
                        svakoj grupi. Već tu možete primijetiti kako bi se loš DPS odrazio na ukupne performanse grupe.
                        Npr – ako uzmemo da u prosjeku DPS rola radi 15-20k DPSa u stvarnom encounteru, a naša trojka
                        radi 5-8k DPSa pojedinačno – tehnički ste hendikepirani u startu s obzirom da efektivno raidate
                        kao da imate 2 čovjeka manje.
                    </p>

                    <p v-reveal>
                        Prije nego se pridružite raid grupi, provjerite svoj gear - armor set, weapone, trinkete,
                        amulete, ringove, rune i sigile... Ne brinite ako niste obučeni u full ascended gear jer će
                        ispravan exotic set biti i više nego dobar pod uvjetom da je sve ostalo kako treba.
                    </p>

                    <p v-reveal>
                        Stoga još jednom – pripremite se za kritike i izmjene u načinu na koji igrate jer svaki
                        pojedinac uvelike utječe na performanse cijelog squada.
                    </p>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="content">
                <h2 v-reveal>Builds & Guides</h2>

                <p v-reveal>
                    Ako nikad niste kročili u raidove, definitivno bacite oko na neki od meta buildova za klasu koju
                    igrate. Generalno ne cjepidlačimo sa full meta setupom, ali apsolutno očekujemo da poznate svoju
                    klasu i kako funkcionira. Buildove možete pronaći na stranicama poput
                    <a href="https://hardstuck.gg/gw2/builds/" target="_blank">Hardstruck</a> ili
                    <a href="https://snowcrows.com/builds?profession=any&category=recommended" target="_blank"
                        >Snowcrows</a>, dok sva ostala pitanja slobodno bacite u #guild-wars kanal na našem Discordu.
                </p>

                <p v-reveal>
                    Kako svaki boss encounter ima specifične mehanike i sitnice na koje treba paziti, mislimo da je
                    najbolji pristup pogledati kako encounter funkcionira i onda probati istu stvar samostalno. Ako ste
                    tip osobe koja preferira čitanje, bacite oko na
                    <a href="https://hardstuck.gg/gw2/guides/raids" target="_blank">Hardstuck raid guide</a>.
                </p>

                <p v-reveal>
                    Za sve ostale vizualnije raidere - niže se nalaze linkovi na video guide koji u kratko objasni sve
                    encountere.
                </p>
            </div>
        </div>

        <RaidWingBoxes />

        <div class="container">
            <div class="content">
                <p v-reveal>
                    Za točno vrijeme i dane kad raidamo, bacite oko na naš Discord ili event prijavu. U praksi prolazimo
                    barem par inicijalnih wingova - najčešće Spirite Vale (W1), Salvation Pass (W2) i Bastion of the
                    Penitent (W4) u kojem se nalaze jedni od lakših bosseva pa je i samim time učenje olakšano. Također,
                    od nedavno – pokušamo uhvatiti i "Emboldened" buff koji se rotira svaki tjedan i dodatno olakšava
                    specifični wing sa dodatnim buffovima.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import RaidingGuidesHeader from '@/components/raids/RaidingGuidesHeader.vue'
import RaidWingBoxes from '@/components/raids/RaidWingBoxes.vue'

import metadata from '@/utils/metadata'

export default {
    name: 'RaidTraining',

    metaInfo: {
        meta: metadata.raids,
        title: 'Raid Training | Croatian Maniacs - Guild Wars 2 guild & Gaming Community',
        link: [{ rel: 'canonical', href: 'https://croatian-maniacs.com/raids' }],
    },

    props: {},

    data() {
        return {}
    },

    created() {},

    components: { RaidWingBoxes, RaidingGuidesHeader },

    computed: {},

    methods: {},
}
</script>
