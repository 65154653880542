import Vue from 'vue'
import VueMeta from 'vue-meta'

import store from './store'

import router from './router'

import scrollReveal from '@/utils/scrollReveal'

import App from './App.vue'

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
})

Vue.config.productionTip = false

require('./scss/main.scss')

Vue.directive('reveal', {
    inserted: (el, bindings) => {
        const delay = bindings.value?.delay || 0

        setTimeout(() => {
            scrollReveal({
                target: el,
                revealThreshold: 0.3,
                toggleClass: {
                    default: {
                        scrollingDown: 'reveal',
                        scrollingUp: '',
                    },

                    revealed: 'loaded',
                },
            })
        }, delay)
    },
})

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#cm-ng')
