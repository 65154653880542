const NAMES = {
    TWITTER_IMAGE: 'twitter:image',
    OG_DESCRIPTION: 'og:description',
}

const defaultMeta = {
    ogType: {
        property: 'og:type',
        content: 'website',
    },
    twitterCard: {
        name: 'twitter:card',
        content: 'summary_large_image',
    },
    ogImage: {
        property: 'og:image',
        content: 'https://croatian-maniacs.com/ogimg.jpg',
    },
    twitterImage: {
        name: NAMES.TWITTER_IMAGE,
        content: 'https://croatian-maniacs.com/ogimg.jpg',
    },
}

const description = {
    home: 'Dobrodošli u regionalnu skupinu zaljubljenika u gaming i druženje',
    raids: 'Jer se legendary armor neće scraftati sam od sebe...',
    strikeMissions: 'Pridružite nam se u večernjem minglanju uz strike misije...',
    eventSignup: 'Prijavi se na CM event!',
    pageNotFound: 'Fooooo! Someone messed with the flux capacitor matrix...',
}

export default {
    home: [
        {
            property: 'og:url',
            content: 'https://croatian-maniacs.com/',
        },
        defaultMeta.ogType,
        {
            property: 'og:title',
            content: 'Croatian Maniacs - Guild Wars 2 guild & Gaming Community',
        },
        {
            property: NAMES.OG_DESCRIPTION,
            content: description.home,
        },
        {
            name: 'description',
            content: description.home,
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard,
    ],

    raids: [
        {
            property: 'og:url',
            content: 'https://croatian-maniacs.com/raids',
        },
        defaultMeta.ogType,
        {
            property: 'og:title',
            content: 'Raid Training | Croatian Maniacs - Guild Wars 2 guild & Gaming Community',
        },
        {
            property: NAMES.OG_DESCRIPTION,
            content: description.raids,
        },
        {
            name: 'description',
            content: description.raids,
        },
        {
            property: 'og:image',
            content: 'https://croatian-maniacs.com/ogimgRaids.jpg',
        },
        {
            name: NAMES.TWITTER_IMAGE,
            content: 'https://croatian-maniacs.com/ogimgRaids.jpg',
        },
        defaultMeta.twitterCard,
    ],

    strikeMissions: [
        {
            property: 'og:url',
            content: 'https://croatian-maniacs.com/strike-missions',
        },
        defaultMeta.ogType,
        {
            property: 'og:title',
            content: 'Strike Missions | Croatian Maniacs - Guild Wars 2 guild & Gaming Community',
        },
        {
            property: NAMES.OG_DESCRIPTION,
            content: description.strikeMissions,
        },
        {
            name: 'description',
            content: description.strikeMissions,
        },
        {
            property: 'og:image',
            content: 'https://croatian-maniacs.com/ogimgStrikes.jpg',
        },
        {
            name: NAMES.TWITTER_IMAGE,
            content: 'https://croatian-maniacs.com/ogimgStrikes.jpg',
        },
        defaultMeta.twitterCard,
    ],

    pageNotFound: [
        defaultMeta.ogType,
        {
            property: 'og:title',
            content: 'Page not found | The fLuX c4Pac1tY mAtr1X is br0K3n...',
        },
        {
            property: NAMES.OG_DESCRIPTION,
            content: description.pageNotFound,
        },
        {
            name: 'description',
            content: description.pageNotFound,
        },
        {
            property: 'og:image',
            content: 'https://croatian-maniacs.com/ogimgNotFound.jpg',
        },
        {
            name: NAMES.TWITTER_IMAGE,
            content: 'https://croatian-maniacs.com/ogimgNotFound.jpg',
        },
        defaultMeta.twitterCard,
    ],

    eventSignup: [
        {
            property: 'og:url',
            content: 'https://croatian-maniacs.com/event-signup',
        },
        defaultMeta.ogType,
        {
            property: 'og:title',
            content: 'Event signup | Croatian Maniacs - Guild Wars 2 guild & Gaming Community',
        },
        {
            property: NAMES.OG_DESCRIPTION,
            content: description.eventSignup,
        },
        {
            name: 'description',
            content: description.eventSignup,
        },
        {
            property: 'og:image',
            content: 'https://croatian-maniacs.com/ogimgRaids.jpg',
        },
        {
            name: NAMES.TWITTER_IMAGE,
            content: 'https://croatian-maniacs.com/ogimgRaids.jpg',
        },
        defaultMeta.twitterCard,
    ],
}
