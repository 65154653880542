<template>
    <div class="activity-blocks">
        <div class="activity-row">
            <div class="guild-activity spirit-vale effect-layla animate-left">
                <div class="overlay-light"></div>
                <div class="activity-content">
                    <h3 v-reveal>Spirit Vale</h3>
                    <div class="activity-description raid-bosses" v-reveal>
                        <a href="https://www.youtube.com/watch?v=qtzMDCVHlLg" target="_blank">Vale Guardian</a>
                        <a href="https://www.youtube.com/watch?v=Ni_-HkRtmm4" target="_blank">Spirit run</a>
                        <a href="https://www.youtube.com/watch?v=B9qbuP9kv4Q" target="_blank">Gorseval</a>
                        <a href="https://www.youtube.com/watch?v=4bqwSrZ2Sr4" target="_blank">Sabetha</a>
                    </div>
                </div>
            </div>
            <div class="guild-activity salvation-pass effect-layla effect-layla-dark animate-right">
                <div class="activity-content dark">
                    <h3 v-reveal>Salvation Pass</h3>
                    <div class="activity-description raid-bosses dark" v-reveal>
                        <a href="https://www.youtube.com/watch?v=WIBTTAPd3ME" target="_blank">Slothasor</a>
                        <a href="https://www.youtube.com/watch?v=ncVYseut8ag" target="_blank">Bandit Trio</a>
                        <a href="https://www.youtube.com/watch?v=q99ocDpSnAI" target="_blank">Matthias</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="activity-row flipped-sm">
            <div class="guild-activity stronghold-of-the-faithful effect-layla effect-layla-dark dark animate-left">
                <div class="activity-content dark">
                    <h3 v-reveal>Stronghold of the Faithful</h3>
                    <div class="activity-description raid-bosses dark" v-reveal>
                        <a href="https://www.youtube.com/watch?v=WFwcnj74lwU" target="_blank">Escort</a>
                        <a href="https://www.youtube.com/watch?v=QIdzfDtxaFQ" target="_blank">Keep construct</a>
                        <a href="https://www.youtube.com/watch?v=mRwLCQ7rUTw" target="_blank">Twisted castle</a>
                        <a href="https://www.youtube.com/watch?v=Iggsq-f3yBw" target="_blank">Xera</a>
                    </div>
                </div>
            </div>
            <div class="guild-activity bastion-of-the-penitent effect-layla animate-right">
                <div class="overlay-light"></div>
                <div class="activity-content">
                    <h3 v-reveal>Bastion of the Penitent</h3>
                    <div class="activity-description raid-bosses" v-reveal>
                        <a href="https://www.youtube.com/watch?v=zPfLE1wwWkw" target="_blank">Cairn</a>
                        <a href=" https://www.youtube.com/watch?v=iHMzAPWoLDU" target="_blank">Mursaat overseer</a>
                        <a href="https://www.youtube.com/watch?v=pLudL0RBgf0" target="_blank">Samarog</a>
                        <a href="https://www.youtube.com/watch?v=gW5HH36X3zU" target="_blank">Deimos</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="activity-row">
            <div class="guild-activity hall-of-chains effect-layla animate-left">
                <div class="overlay-light"></div>
                <div class="activity-content">
                    <h3 v-reveal>Hall of Chains</h3>
                    <div class="activity-description raid-bosses" v-reveal>
                        <a href="https://www.youtube.com/watch?v=Nw6D9_RcJhs" target="_blank">Desmina</a>
                        <a href="https://www.youtube.com/watch?v=-84PZQGCmyI" target="_blank">River of Souls</a>
                        <a href="https://www.youtube.com/watch?v=cKUcf_ez9Ec" target="_blank">Statues of Grenth</a>
                        <a href="https://www.youtube.com/watch?v=5gFxLKJI9ds" target="_blank">Dhuum</a>
                    </div>
                </div>
            </div>
            <div class="guild-activity mythwright-gambit effect-layla effect-layla-dark animate-right">
                <div class="activity-content dark">
                    <h3 v-reveal>Mythwright Gambit</h3>
                    <div class="activity-description raid-bosses dark" v-reveal>
                        <a href="https://www.youtube.com/watch?v=B7VQAZuV-6o" target="_blank">Conjured Amalgamate</a>
                        <a href="https://www.youtube.com/watch?v=RUQQhyE_YiA" target="_blank">Twin Largos</a>
                        <a href="https://www.youtube.com/watch?v=nU_yQtk_8oo" target="_blank">Qadim</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="activity-row flipped-sm">
            <div class="guild-activity event-signup effect-layla effect-layla-dark dark animate-left">
                <div class="activity-content dark">
                    <h3 v-reveal>Raiding prijave</h3>
                    <div class="activity-description raid-bosses" v-reveal>
                        Ne propustite se <router-link to="/event-signup">prijaviti</router-link> za nadolazeći run!
                    </div>
                </div>
            </div>
            <div class="guild-activity key-of-ashadim effect-layla animate-right">
                <div class="overlay-light"></div>
                <div class="activity-content">
                    <h3 v-reveal>The Key of Ahdashim</h3>
                    <div class="activity-description raid-bosses" v-reveal>
                        <a href="https://www.youtube.com/watch?v=jwjqrsBQhEo" target="_blank">Sabir</a>
                        <a href="https://www.youtube.com/watch?v=T_RwWm0Jj4k" target="_blank">Adina</a>
                        <a href="https://www.youtube.com/watch?v=v1ZFeRot8zg" target="_blank">Qadim the Peerless</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RaidWingBoxes',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
