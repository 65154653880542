<template>
    <div class="activity-blocks">
        <div class="activity-row">
            <div class="guild-activity icebrood-saga effect-layla animate-left">
                <div class="overlay-light"></div>
                <div class="activity-content">
                    <h3 v-reveal>Icebrood Saga</h3>
                    <div class="activity-description raid-bosses" v-reveal>
                        <a href="https://www.youtube.com/watch?v=Ryn-KdmTR_Y&t=84s" target="_blank">Shiverpeaks Pass</a>
                        <a href="https://www.youtube.com/watch?v=Ryn-KdmTR_Y&t=112s" target="_blank">Voice & Claw of the Fallen</a>
                        <a href="https://www.youtube.com/watch?v=Ryn-KdmTR_Y&t=131s" target="_blank">Fraenir of Jormag</a>
                        <a href="https://www.youtube.com/watch?v=Ryn-KdmTR_Y&t=252s" target="_blank">Boneskinner</a>
                        <a href="https://www.youtube.com/watch?v=Ryn-KdmTR_Y&t=154s" target="_blank">Whisper of Jormag</a>
                        <a href="https://www.youtube.com/watch?v=Ryn-KdmTR_Y&t=325s" target="_blank">Cold War</a>
                        <a href="https://www.youtube.com/watch?v=Ryn-KdmTR_Y&t=390s" target="_blank">Forging Steel</a>
                    </div>
                </div>
            </div>
            <div class="guild-activity end-of-dragons effect-layla effect-layla-dark animate-right">
                <div class="activity-content dark">
                    <h3 v-reveal>End of Dragons</h3>
                    <div class="activity-description raid-bosses dark" v-reveal>
                        <a href="https://www.youtube.com/watch?v=FddzvSw7Ss8&t=7s" target="_blank">Aetherblade Hideout</a>
                        <a href="https://www.youtube.com/watch?v=FddzvSw7Ss8&t=102s" target="_blank">Xunlai Jade Junkyard</a>
                        <a href="https://www.youtube.com/watch?v=FddzvSw7Ss8&t=160s" target="_blank">Kaineng Overlook</a>
                        <a href="https://www.youtube.com/watch?v=FddzvSw7Ss8&t=287s" target="_blank">Harvest Temple</a>
                        <a href="https://www.youtube.com/watch?v=q0gDvzZ3Gtw" target="_blank">Old Lion's Court</a>
                    </div>

                    <div class="mt-20">
                        <h3 v-reveal>Secrets of the Obscure</h3>
                        <div class="activity-description raid-bosses dark" v-reveal>
                            <a href="https://www.youtube.com/watch?v=VC8WFUVWVbQ&t=8s" target="_blank">Cosmic Observatory</a>
                            <a href="https://youtu.be/VC8WFUVWVbQ?t=82" target="_blank">Temple of Febe</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StrikeBoxes',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
