<template>
    <div class="hero-header">
        <div class="hero-header strikes-header-bg"></div>

        <div class="stripes">
            <div class="container">
                <h1 class="reveal" v-reveal="{ delay: 180 }">Strike <span class="text-highlight">nights</span></h1>
                <h2 class="reveal" v-reveal="{ delay: 280 }">
                    Pridružite nam se u večernjem minglanju uz strike misije
                </h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StrikeGuidesHeader',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
