export default [
    {
        name: 'CALLmeSCOOBY',
        description:
            "Scooby je dugogodišnji član CM-a koji je otkrio gaming u 90'-ima kad je prvi put sjeo za volan u Need for Speedu. Od tada igra sve što mu izgleda zanimljivo a predstavlja se kao variety streamer koji ipak naginje racing i FPS naslovima.",
        links: {
            twitch: 'https://www.twitch.tv/callmescooby',
            instagram: 'https://www.instagram.com/callmescooby/',
            youtube: 'https://www.youtube.com/CALLmeSCOOBY',
            twitter: 'https://twitter.com/CALLmeSCOOBY87',
        },
        bgStyle: 'subaru-bg',
        isReversed: true,
    },

    {
        name: 'AlexiusY',
        description:
            'AlexiusY, ilitiga Teacher Luka, je profesor engleskog i crni mag s pedigreom u streamanju raznih igara, od flashy japanskih RPG-ova do čudnih indie izmišljotina. Recenzira igre za FFA.hr i vodi svoj tjedni gaming podcast "Finalni Fantazisti"!',
        links: {
            twitch: 'https://www.twitch.tv/alexiusy/',
            youtube: 'https://www.youtube.com/user/AlexiusYindor',
            twitter: 'https://twitter.com/AlexiusY',
        },
        bgStyle: '',
        isReversed: false,
    },

    {
        name: 'Rikkurumi',
        description:
            'Rikkurumi iliti skraćeno Rikku/Rikki/Rumi, ponekad i Kikiriki, je gamerica čiji nickname seže iz World of Warcrafta dok gaming smatra velikom ljubavi još od djetinjstva. Za sebe kaže kako nije pro gamer niti kompetitivna. Iako joj je Witcher 3 najdraža igra, možete je uhvatiti kako streama Apex Legends, Heores of the Storm, Crash Bandicoot i GTA. Voli sve što vole mladi, a naročito pizzu s ananasom!',
        links: {
            twitch: 'https://www.twitch.tv/rikkurumi',
            instagram: 'https://www.instagram.com/rikkurumi/',
            youtube: 'https://www.youtube.com/channel/UCFlijV-JwZYcLk9mA7_sxEg',
            twitter: 'https://twitter.com/rikkurumi',
        },
        bgStyle: 'apex-bg',
        isReversed: true,
    },

    {
        name: 'Madgnu',
        description:
            'Madgnu nije samo mješavina konja i bika, već je i veteran u svijetu gaminga koji je u virtualne svijetove zalutao još u vrijeme starog Aladina i Super Maria. Poslije originalnog NESa došao je i PC, ali i druge konzole. Danas Gnu uživa u MMO naslovima, ali i svim drugim igrama koje mu dođu pod ruku. Gnu ne diskriminira prema žanru.',
        links: {
            trovo: 'https://trovo.live/s/MadGnu/',
        },
        bgStyle: '',
        isReversed: false,
    },
]
