<template>
    <div class="croatian-maniacs">
        <div class="container">
            <div class="content">
                <h2 v-reveal>Croatian Maniacs</h2>

                <p v-reveal>
                    Croatian Maniacs [CM] je jedan od najstarijih i najvećih guildova na ovom području, a svoje korjene
                    vuče još iz mladih dana originalnog Guild Warsa. Tijekom vremena se vodstvo guilda blago mijenjalo,
                    ali je osnova tima vječito prisutna makar i samo lurkanjem na Discordu.
                </p>

                <p v-reveal>
                    Organiziramo tjedne guild evente, raidove te dnevne dungeon runove, dok će naši veterani osigurati
                    svim novim pridošlicama savjete i informacije o igri bilo da se radi o PvE, PvP ili WvW sadržaju.
                </p>
            </div>
        </div>

        <CroatianManiacsActivityBlocks />

        <GuildRules />
    </div>
</template>

<script>
import CroatianManiacsActivityBlocks from '@/components/homepage/croatianManiacs/CroatianManiacsActivityBlocks.vue'
import GuildRules from '@/components/homepage/croatianManiacs/GuildRules.vue'

export default {
    name: 'CroatianManiacsInfo',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: { GuildRules, CroatianManiacsActivityBlocks },

    computed: {},

    methods: {},
}
</script>
