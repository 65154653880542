<template>
    <div class="container guild-rules">
        <div class="content">
            <h2 v-reveal>Guild pravila</h2>

            <ul>
                <li v-reveal>
                    Budite pristojni prema svim igračima bez obzira da li su članovi guilda, saveznici ili suparnici u
                    PvP-u. Vrijeđanje ili bilo kakva vrsta provokacije se loše odražava na sve članove stoga takvo
                    ponašanje ne toleriramo. Poštovanje treba zaslužiti, a uljudnost mora postojati kako bi održali
                    prijateljsku atmosferu unutar guilda.
                </li>

                <li v-reveal>
                    Psovanje se ne potiče ali se može razumjeti u određenim uvjetima. Izbjegavajte nepristojno ponašanje
                    u otvorenom chatu (map / trade / squad) jednako kao i u Guild / Aliance chatu koliko god je to
                    moguće.
                </li>

                <li v-reveal>Od svih članova guilda se očekuje zrelo i odraslo ponašanje.</li>

                <li v-reveal>
                    Nije dopušteno nikakvo varanje, hakiranje ili eksploitanje. Ovakve pojedince prijavljujemo ArenaNetu
                    i izbacujemo iz guilda.
                </li>

                <li v-reveal>
                    Nema moljakanja samo zato što je netko veći level i/ili ima više golda. To ne znači da si nužno može
                    priuštit da vam pomogne. Ako vam nešto treba – pitajte u guild chatu ili Discordu. Tko god može
                    pomoći – sigurno će se javiti.
                </li>

                <li v-reveal>
                    U praksi ne kickamo neaktivne članove, ali je pristojno najaviti duži rok neaktivnosti oficirima.
                </li>

                <li v-reveal>
                    Kršenje pravila će oficiri pokušati disciplinirati što je prije moguće. Ako su oficiri prisutni,
                    skrenite im pažnju na prekršitelja uz što više informacija o problemu (ako ste u mogućnosti,
                    priložite screenshote). Ako oficiri nisu prisutni, pokušajte prekršitelju skrenuti pozornost na
                    pravila. To učinite preko privatnih poruka i bez drame.
                </li>

                <li v-reveal>
                    Oficiri koji su neaktivni duže od tri tjedna će biti demotani u status osnovnog člana guilda s
                    mogućnosti ponovne promocije nakon povratka.
                </li>

                <li v-reveal>
                    Ako je oficir demotan od strane leadera ili većinskom odlukom ostaka oficira iz bilo kojeg razloga,
                    ta odluka se mora poštovati. Za bilo kakvu naknadnu promjenu članstva ovakvih pojedinaca potrebna je
                    zajednička odluka oficira.
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GuildRules',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
